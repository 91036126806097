import React from "react";
import bgimg from "../../Assets/detail-result-bg-img.png";
import character from "../../Assets/cahracter1.png";
const DetailResultPageTopSection = ({ item }) => {
  return (
    <div>
      <div className="relative">
        <img src={bgimg} alt="bg-img" className="w-full h-[25rem]  sm-max:h-[23.25rem] sm-max:object-cover " />
        <div className="absolute top-0 left-0 flex flex-col justify-between h-full px-8 pt-[2rem] pb-8 sm-max:px-6 sm-max:pb-6">
          <div>
            <p className="text-[5rem] text-white font-semibold leading-[5rem] sm-max:text-[2rem] sm-max:leading-[3rem] sm-max:w-[70%]">
              {item?.name}
            </p>
            {/* <p className="text-2xl font-semibold text-white ">
              {item?.prediction?.mbti.entity.code}-
              {item?.prediction?.holland.entity.code}
            </p> */}
          </div>
          <div>
            <p className="text-xl text-white w-[60%] mb-[1.625rem] sm-max:text-base sm-max:w-full sm-max:mb-[1rem] ">
              {item?.simpleDesc?.split(".")[0]}.
            </p>
            <p className="text-2xl font-bold text-white sm-max:text-base">
              {/* Friendly | Energetic | Creative | Innovative */}
            </p>
          </div>
        </div>
        <div className="absolute top-5 right-0 md-max:top-[6rem] sm-max:-right-5 sm-max:-top-0">
          <img src={character} alt="character" className="sm-max:w-[11.75rem] sm-max:h-[11.75rem] md-max:h-[15rem] md-max:w-[15rem]" />
        </div>
      </div>
    </div>
  );
};

export default DetailResultPageTopSection;
