import React, { useEffect } from "react";
import { GoDotFill } from "react-icons/go";
import Layout from "../Layout/Layout";
import './TermsCondition.css'
const TermsCondition = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <Layout>
      <div className="terms-condition px-[6rem] py-[5rem] flex flex-col gap-10 md-max:px-[2rem] sm-max:px-[1rem] sm-max:py-[2rem] sm-max:gap-5">
        <p>
          Welcome to USlot.ai! These Terms and Conditions govern your access to
          and use of the USlot.ai website and services. By accessing or using
          USlot.ai, you agree to be bound by these Terms and Conditions. If you do
          not agree with any part of these Terms and Conditions, please do not use
          our website or services.
        </p>
        <div className='contents'>
          <p className='sub-heading'>1. Use of Our Services</p>
          <p className='point'><span><GoDotFill /></span>Account Registration: In order to access certain features of USlot.ai, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account</p>
          <p className='point'><span><GoDotFill /></span>Accuracy of Information: You agree to provide accurate, current, and complete information when using USlot.ai. You also agree not to impersonate any person or entity or misrepresent your affiliation with any person or entity.</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>2. User Conduct</p>
          <p className='point font-semibold'><span><GoDotFill /></span>Prohibited Activities: You agree not to engage in any of the following activities while using USlot.ai:</p>
          <div className="px-10 flex flex-col gap-3">
            <p className='point'><span><GoDotFill /></span>Violating any applicable laws, regulations, or third-party rights.</p>
            <p className='point'><span><GoDotFill /></span>Interfering with or disrupting the operation of USlot.ai or the servers or networks connected to it.</p>
            <p className='point'><span><GoDotFill /></span>Attempting to gain unauthorized access to any portion or feature of USlot.ai or any other systems or networks connected to it.</p>
            <p className='point'><span><GoDotFill /></span>Using any automated means to access or interact with USlot.ai.</p>
          </div>
          <p className='point'><span><GoDotFill /></span>Content Guidelines: You agree not to post, upload, or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, obscene, or otherwise objectionable.</p>

        </div>
        <div className='contents'>
          <p className='sub-heading'>3. Intellectual Property Rights</p>
          <p className='point'><span><GoDotFill /></span>Ownership: All content and materials on USlot.ai, including text, graphics, logos, images, and software, are the property of USlot.ai or its licensors and are protected by copyright, trademark, and other intellectual property laws.</p>
          <p className='point'><span><GoDotFill /></span>License: USlot.ai grants you a limited, non-exclusive, non-transferable license to access and use the content and materials on our website for your personal, non-commercial use only.</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>4. Disclaimer of Warranties</p>
          <p className='point'><span><GoDotFill /></span>No Guarantees: USlot.ai provides career predictions and guidance based on available data and algorithms, but we do not guarantee the accuracy, completeness, or reliability of our predictions. Your career decisions are ultimately your own responsibility.</p>
          <p className='point'><span><GoDotFill /></span>No Warranty: USlot.ai is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. We disclaim all warranties, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>5. Limitation of Liability</p>
          <p className='point'><span><GoDotFill /></span>Indirect Damages: In no event shall USlot.ai be liable for any indirect, incidental, special, consequential, or punitive damages, including, but not limited to, loss of profits, data, use, or goodwill, arising out of or in connection with your use of our website or services.</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>6. Indemnification</p>
          <p className='point'><span><GoDotFill /></span>You agree to indemnify, defend, and hold harmless USlot.ai and its officers, directors, employees, agents, and affiliates from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or in connection with your use of USlot.ai or any violation of these Terms and Conditions.</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>7. Changes to these Terms and Conditions</p>
          <p className='point'><span><GoDotFill /></span>We reserve the right to update or modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting the revised Terms and Conditions on our website. Your continued use of USlot.ai after the posting of changes constitutes your acceptance of such changes.</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>8. Governing Law and Jurisdiction</p>
          <p className='point'><span><GoDotFill /></span>These Terms and Conditions shall be governed by and construed in accordance with the laws of [insert jurisdiction]. Any dispute arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of [insert jurisdiction].</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>9. Contact Us</p>
          <p>If you have any questions, concerns, or feedback about these Terms and Conditions, please contact us at <a className="text-blue-500" href="mailto:uslotedu@gmail.com">uslotedu@gmail.com</a> </p>
          <p>Thank you for using USlot.ai. We hope our platform helps you navigate your career path with confidence and clarity.</p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsCondition;
