import React, { useEffect } from "react";
import "./ShippingDelivery.css";
import Layout from "../Layout/Layout";
import { GoDotFill } from "react-icons/go";
const ShippingDelivery = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <Layout>
      <div className="shipping-delivery px-[6rem] py-[5rem] flex flex-col gap-10 md-max:px-[2rem] sm-max:px-[1rem] sm-max:py-[2rem] sm-max:gap-5">
        <p>
          At USlot.ai, we provide our services entirely online, and therefore,
          there are no physical products to be shipped or delivered. All our
          career prediction and guidance services are accessible through our
          website and digital platforms.
        </p>
        <div className='contents'>
          <p className='sub-heading'>1. Service Delivery</p>
          <p className='point'><span><GoDotFill /></span>Instant Access: Upon successful registration and account creation on USlot.ai, you will have immediate access to our career prediction and guidance services.</p>
          <p className='point'><span><GoDotFill /></span>Digital Services: Our services are delivered entirely online through our website and digital platforms. You can access your account and utilize our services from any internet-connected device at your convenience.</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>2. No Shipping Required</p>
          <p>Since USlot.ai does not offer physical products or tangible goods, there is no shipping involved in our service delivery process. You will not receive any physical shipments or deliveries from us.</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>3. Accessibility</p>
          <p>We strive to ensure that our website and services are accessible to users worldwide. However, please note that access to our website and services may be restricted in certain regions due to legal or regulatory restrictions.</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>4. Customer Support</p>
          <p>If you encounter any issues or have questions regarding accessing or using our services, please contact our customer support team at contact@uslot.ai. We are committed to providing prompt assistance and resolving any concerns you may have.</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>5. Changes to this Policy</p>
          <p>We reserve the right to update or modify this Shipping and Delivery Policy at any time. Any changes will be effective immediately upon posting the revised policy on our website. We encourage you to review this policy periodically for any updates.</p>
        </div>
        <div className='contents'>
          <p className='sub-heading'>6. Contact Us</p>
          <p>If you have any questions or concerns about our Shipping and Delivery Policy, please contact us at <a className="text-blue-500" href="mailto:uslotedu@gmail.com">uslotedu@gmail.com</a> </p>
          <p>Thank you for choosing USlot.ai for your career prediction and guidance needs. We are dedicated to providing you with a seamless and accessible online experience.</p>
        </div>
      </div>
    </Layout>
  );
};

export default ShippingDelivery;
