import React, { useEffect } from "react";
import "./Login.css";
import loginlogo from "../../Assets/my-aptitude-ai-uslot.png";
import google from '../../Assets/google.svg'
import config from '../../config.json';
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import { useRecoilState } from "recoil";
import { isLoginLoader } from "../../utils/recoil";

const Login = () => {
  const tokenCookie = Cookies.get('token', { domain: config.cookieDomain });
  const isPaidCookie = Cookies.get('role', { domain: config.cookieDomain });
  const isTestTaken = Cookies.get('isTestAttended', { domain: config.cookieDomain });
  const navigate = useNavigate();
  const [loading, setLoading] = useRecoilState(isLoginLoader);

  useEffect(() => {
    if (tokenCookie && isPaidCookie === 'ROLE_PAID_USER') {
      navigate('/instruction');
    } else if (tokenCookie) {
      navigate(-2);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div className="login-page-main">
      {(tokenCookie || loading) ? (
        <LoadingPage />
      ) :
        <>
          <div className="login-image-section">
            <img src="" alt="" />
          </div>
          <div className="login-content-section">
            <div className="login-content">
              <div>
                <Link to={"/"}> <img src={loginlogo} alt="logo" className="object-cover h-[5rem]" /></Link>
              </div>
              <div className="login-button-section">
                <h1>Sign Up or Login to Uslot</h1>
                <div className="single-button-google">
                  <div className="google">
                    <img src={google} alt="" />
                    <button onClick={() => [setLoading(true), window.location.href = config.domain + '/oauth2/authorization/google']}>Sign in with Google</button>

                  </div>
                </div>
                <h2 className="para-of-login">Unable to sign in? <span><a href="mailto:uslotedu@gmail.com">Contact Support</a></span> </h2>
              </div>
            </div>
          </div>
        </>}
    </div>
  );
};

export default Login;
